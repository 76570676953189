<template>
  <div id="EquipmentMaintenance">
    <div class="top">
      <div class="VisualData">
        <div class="VisualItem" v-show="hasDevice">
          <!-- <div class="VisualItem"> -->
          <div class="Visual" id="UVA"></div>
          <div class="text">
            <h1>无人机保养状态</h1>
            <div class="status1">
              <div></div>
              <h2>无需保养</h2>
              <h3>{{ (100 - insudevice).toFixed(2) + '%' }}</h3>
            </div>
            <div class="status2">
              <div></div>
              <h2>建议保养</h2>
              <h3>{{ insudevice.toFixed(2) + '%' }}</h3>
            </div>
          </div>
        </div>
        <div class="VisualItem" v-show="!hasDevice" style="font-size: 18px">
          暂无注册无人机
        </div>
        <div class="VisualItem" v-show="hasBatter">
          <div class="Visual" id="Battery"></div>
          <div class="text">
            <h1>电池保养状态</h1>
            <div class="status1">
              <div></div>
              <h2>无需保养</h2>
              <h3>{{ (100 - insubatter).toFixed(2) + '%' }}</h3>
            </div>
            <div class="status2">
              <div></div>
              <h2>建议保养</h2>
              <h3>{{ insubatter.toFixed(2) + '%' }}</h3>
            </div>
          </div>
        </div>
        <div class="VisualItem" v-show="!hasBatter" style="font-size: 18px">
          暂无注册电池
        </div>
      </div>
      <div class="search">
        <div class="box">
          <h1>无人机保养状态</h1>
          <div class="searchItem">
            <h2>飞行时长</h2>
            <div>
              <Input
                v-model="searchData.flytime1"
                :number="true"
                style="width: 100%"
              />
            </div>
            <h3>小时</h3>
          </div>
          <div class="searchItem">
            <h2>飞行次数</h2>
            <div>
              <Input
                v-model="searchData.flycount"
                :number="true"
                style="width: 100%"
              />
            </div>
            <h3>次</h3>
          </div>
          <div class="searchIcon" @click="updateEquipmentList">
            <i class="ivu-icon ivu-icon-ios-search"></i>
          </div>
        </div>
      </div>
      <div class="search">
        <div class="box">
          <h1>电池保养状态</h1>
          <div class="searchItem">
            <h2>飞行次数</h2>
            <div>
              <Input
                v-model="searchData.batterflycount"
                :number="true"
                style="width: 100%"
              />
            </div>
            <h3>次</h3>
          </div>
          <div class="searchItem">
            <h2>电池寿命</h2>
            <div>
              <Input
                v-model="searchData.batterlife"
                :number="true"
                style="width: 100%"
              />
            </div>
            <h3>%</h3>
          </div>
          <div class="searchIcon" @click="updateEquipmentList">
            <i class="ivu-icon ivu-icon-ios-search"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <Row>
        <Col span="24" class="Data-tabs" style="padding: 0">
          <Tabs type="card">
            <TabPane label="无人机建议保养列表">
              <div class="TabItem">
                <Table
                  :loading="loading"
                  :columns="UAVmaintenanceList"
                  :data="UAVmaintenancedata"
                ></Table>
              </div>
            </TabPane>
            <TabPane label="电池建议保养列表">
              <div class="TabItem">
                <Table
                  :loading="loading"
                  :columns="batterMaintenanceList"
                  :data="batterMaintenancedata"
                ></Table>
              </div>
            </TabPane>
            <!--<TabPane label="电池异常列表">-->
            <!--<div class="TabItem">-->
            <!--<Table-->
            <!--:loading="loading"-->
            <!--:columns="batterAbnormalList"-->
            <!--:data="batterAbnormaldata"-->
            <!--&gt;</Table>-->
            <!--</div>-->
            <!--</TabPane>-->
            <TabPane label="保养建议">
              <div class="TabItem">
                <div class="suggestBox">
                  <div class="suggestUVA">
                    <h1>无人机保养手册</h1>
                    <h2>
                      飞行器达到其中一个预设保养条件，无人机管理平台会提醒您对飞行器进行保养。您可以在“保养列表”中看到提醒。当飞行器的状态为建议保养时，您需要对飞行器进行以下检查。
                    </h2>
                    <h2>1.检查飞行是否有裂缝；</h2>
                    <h2>2.螺丝是否拧紧；</h2>
                    <h2>3.桨叶是否正常，是否有异响；</h2>
                    <h2>4.电机转动是否正常，是否有异响；</h2>
                    <h2>5.云台是否能够转动；</h2>
                    <h2>6.扬声器确保干净，如果有异物请及时清理。</h2>
                  </div>
                  <Divider />
                  <div class="suggestBattery">
                    <h1>电池保养手册</h1>
                    <h2>
                      1.请不要将电池飞行至0%电量，建议将“严重低电量报警”设置为15%，如果飞行器自动返航后，不建议在强行起飞飞行；
                    </h2>
                    <h2>
                      2.飞行后电池较热，高温下充电会加速电芯老化，请冷却到室温再充电；
                    </h2>
                    <h2>
                      3.请使用DJI官方充电设备，底纹下大电流充电会损伤电芯，严重时有安全隐患，官方充电设备在电池温度低的时候会降低充电电流，甚至停止充电以保护电芯；
                    </h2>
                    <h2>
                      4.如果电池外壳明显鼓起则表示电池内部有问题，可能是电芯膨胀导致，建议废弃处理；
                    </h2>
                    <h2>5.如果过放至无法充电，建议废弃处理。</h2>
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { DeviceMaintainData } from '@/utils/javaApi.js'
export default {
  name: 'EquipmentMaintenance',
  props: ['teamID'],
  watch: {
    teamID: function (val) {
      this.updateEquipmentList()
    },
  },
  data: function () {
    return {
      userType: '',
      userInfo: {},
      Project1info: {},
      UAV: null,
      batter: null,
      UAVoption: {
        tooltip: {
          trigger: 'item',
          formatter: '{b}: <br/>{c} ({d}%)',
        },
        color: ['#FF8A78', '#FECB27'],
        series: [
          {
            name: '无人机建议保养比例',
            type: 'pie',
            radius: ['40%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '12',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 0, name: '无需保养' },
              { value: 0, name: '建议保养' },
            ],
          },
        ],
      },
      batterOption: {
        tooltip: {
          trigger: 'item',
          formatter: '{b}: <br/>{c} ({d}%)',
        },
        color: ['#FF8A78', '#FECB27'],
        series: [
          {
            name: '电池建议保养比例',
            type: 'pie',
            radius: ['40%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '12',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 0, name: '无需保养' },
              { value: 0, name: '建议保养' },
            ],
          },
        ],
      },
      EquipmentData: null,
      devicelist: null, //无人机建议保养列表
      batterlist: null, //电池保养建议列表
      batterbad: null, //电池异常列表
      insubatter: 0, //电池建议保养比例
      insudevice: 0, //无人机建议保养比例
      UAVmaintenanceList: [
        {
          title: '单位名称',
          key: 'device_name',
          align: 'center',
        },
        {
          title: '产品序列号',
          key: 'serial_code',
          align: 'center',
        },
        {
          title: '品牌',
          key: 'brand',
          align: 'center',
        },
        {
          title: '型号',
          key: 'model',
          align: 'center',
        },
        {
          title: '总飞行次数',
          key: 'flycount',
          align: 'center',
        },
        {
          title: '总飞行时长',
          key: 'flytime',
          align: 'center',
          render: (h, params) => {
            let seconds = params.row.flytime
            if (parseInt(seconds) > 0) {
              let hour = parseInt(seconds / 3600)
              let minute = parseInt((seconds - 3600 * hour) / 60)
              seconds = seconds - 3600 * hour - 60 * minute

              return h(
                'span',
                (hour ? hour + '小时' : '') +
                  (minute ? minute + '分' : '') +
                  seconds +
                  '秒'
              )
            } else {
              return h('span', '0秒')
            }
          },
        },
        {
          title: '最后使用',
          key: 'lastusetime',
          width: 180,
          align: 'center',
        },
        // {
        //     title: '操作',
        //     align: 'center',
        //     render: (h, params) => {
        //         return h('div', [
        //             h('Button', {
        //                 props: {
        //                     type: 'error',
        //                     size: 'small'
        //                 },
        //                 on: {
        //                     click: () => {
        //
        //                     }
        //                 }
        //             }, '已完成保养')
        //         ]);
        //     }
        // }
      ],
      UAVmaintenancedata: [],
      loading: false,
      batterMaintenanceList: [
        {
          title: '#',
          key: 'id',
          width: 60,
          align: 'center',
        },
        {
          title: '电池类型',
          key: 'battertype',
          align: 'center',
        },
        {
          title: '电池序列号',
          key: 'battercode',
          align: 'center',
        },
        {
          title: '总飞行次数',
          key: 'flycount',
          align: 'center',
        },
        // {
        //   title: "总飞行时长",
        //   key: "flytime",
        //   align: "center",
        // },
        {
          title: '循环次数',
          key: 'cyclecount',
          align: 'center',
        },
        {
          title: '电池寿命',
          key: 'batterlife',
          align: 'center',
        },
        {
          title: '最后使用',
          key: 'lastusetime',
          width: 180,
          align: 'center',
        },
        // {
        //     title: '操作',
        //     align: 'center',
        //     render: (h, params) => {
        //         return h('div', [
        //             h('Button', {
        //                 props: {
        //                     type: 'error',
        //                     size: 'small'
        //                 },
        //                 on: {
        //                     click: () => {
        //
        //                     }
        //                 }
        //             }, '已完成保养')
        //         ]);
        //     }
        // }
      ],
      batterMaintenancedata: [],
      batterAbnormalList: [
        {
          title: '#',
          key: 'id',
          width: 60,
          align: 'center',
        },
        {
          title: '电池类型',
          key: 'battertype',
          align: 'center',
        },
        {
          title: '电池序列号',
          key: 'battercode',
          align: 'center',
        },
        {
          title: '总飞行次数',
          key: 'flycount',
          align: 'center',
        },
        // {
        //   title: '总飞行时长',
        //   key: 'flytime',
        //   align: 'center',
        // },
        {
          title: '循环次数',
          key: 'cyclecount',
          align: 'center',
        },
        {
          title: '电池寿命',
          key: 'batterlife',
          align: 'center',
        },
        {
          title: '最后使用',
          key: 'lastusetime',
          width: 180,
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  on: {
                    click: () => {},
                  },
                },
                '排除异常'
              ),
            ])
          },
        },
      ],
      batterAbnormaldata: [],
      searchData: {
        flytime1: 200,
        flytime: 200,
        flycount: 100,
        batterflycount: 100,
        batterlife: 60,
      },
      hasDevice: true,
      hasBatter: true,
    }
  },
  mounted() {
    this.userType = sessionStorage.getItem('userType')
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))
    this.Project1info = JSON.parse(sessionStorage.getItem('Project1info'))

    this.UVA = this.$echarts.init(document.getElementById('UVA'))
    this.UVA.setOption(this.UAVoption)
    this.Battery = this.$echarts.init(document.getElementById('Battery'))
    this.Battery.setOption(this.batterOption)
    this.updateEquipmentList()
    console.log(
      '单尼玛呢单位名称,自己文件里不说,谁尼玛整天翻你妈的聊天记录看你妈今天又发什么骚!'
    )
  },
  methods: {
    updateEquipmentList() {
      if (
        this.searchData.flytime1 === '' ||
        this.searchData.flycount === '' ||
        this.searchData.batterflycount === '' ||
        this.searchData.batterlife === ''
      ) {
        this.$Message.warning('查询条件不能为空')
        return
      }
      if (
        typeof this.searchData.flytime1 !== 'number' ||
        typeof this.searchData.flycount !== 'number' ||
        typeof this.searchData.batterflycount !== 'number' ||
        typeof this.searchData.batterlife !== 'number'
      ) {
        this.$Message.warning('查询条件只能为数字')
        return
      }
      let data = {
        flytime: this.searchData.flytime1 * 60 * 60,
        flycount: Number(this.searchData.flycount),
        batterflycount: Number(this.searchData.batterflycount),
        batterlife: Number(this.searchData.batterlife),
        team_id: this.teamID,
      }
      this.$post(DeviceMaintainData(), data).then((res) => {
        console.log('数据更新', res)
        if (res.code == 1) {
          this.EquipmentData = res.data
          this.UAVmaintenancedata = this.EquipmentData.devicelist
          this.batterMaintenancedata = this.EquipmentData.batterlist
          this.batterMaintenancedata.forEach((e) => {
            e.batterlife = e.batterlife * 100 + '%'
          })
          this.batterAbnormaldata = this.EquipmentData.batterbad
          this.$Message.info('数据更新完成')
          if (this.EquipmentData.insubatter.all == 0) {
            this.hasBatter = false
          } else {
            this.hasBatter = true
          }
          if (this.EquipmentData.insudevice.all == 0) {
            this.hasDevice = false
          } else {
            this.hasDevice = true
          }
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
  },
  watch: {
    //保养数据改变了
    EquipmentData(val) {
      console.log('保养数据改变了', val)
      this.devicelist = val.devicelist //无人机建议保养列表
      this.batterlist = val.batterlist //电池保养建议列表
      this.batterbad = val.batterbad //电池异常列表
      if (val.insubatter.all == 0) {
        this.hasBatter = false
      } else {
        if (val.insubatter.isnu == 0) {
          this.insubatter = 0
        } else {
          this.insubatter = (val.insubatter.isnu / val.insubatter.all) * 100 //电池建议保养比例
        }
        // 修改电池的可视化数据
        this.Battery.setOption({
          series: [
            {
              // 根据名字对应到相应的系列
              name: '电池建议保养比例',
              data: [
                {
                  value:
                    this.EquipmentData.insubatter.all -
                    this.EquipmentData.insubatter.isnu,
                  name: '无需保养',
                },
                {
                  value: this.EquipmentData.insubatter.isnu,
                  name: '建议保养',
                },
              ],
            },
          ],
        })
      }

      if (val.insudevice.all == 0) {
        this.hasDevice = false
      } else {
        if (val.insudevice.isnu == 0) {
          this.insudevice = 0
        } else {
          this.insudevice = (val.insudevice.isnu / val.insudevice.all) * 100 //无人机建议保养比例
        }

        // 修改无人机的可视化数据
        console.log(
          'num',
          Number(
            this.EquipmentData.insudevice.all -
              this.EquipmentData.insudevice.isnu
          )
        )
        this.UVA.setOption({
          series: [
            {
              // 根据名字对应到相应的系列
              name: '无人机建议保养比例',
              data: [
                {
                  value: Number(
                    this.EquipmentData.insudevice.all -
                      this.EquipmentData.insudevice.isnu
                  ),
                  name: '无需保养',
                },
                {
                  value: this.EquipmentData.insudevice.isnu,
                  name: '建议保养',
                },
              ],
            },
          ],
        })
      }
    },
    teamID: function (val) {
      this.userInfo.team_id = val
      this.updateEquipmentList()
    },
  },
}
</script>

<style scoped lang="scss">
#EquipmentMaintenance {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .VisualData {
      width: 1048px;
      height: 254px;
      box-shadow: 0px 3px 6px #000;
      border-radius: 4px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .VisualItem {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .Visual {
          width: 230px;
          height: 230px;
          margin-right: 40px; //还需要改样式
        }
        .text {
          h1 {
            font-size: 24px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 26px;
          }
          .status1 {
            display: flex;
            align-items: center;
            > div {
              width: 20px;
              height: 20px;
              background-color: #ff8a78;
              border-radius: 20px;
              margin-right: 18px;
            }
            h2 {
              @include sc(20, #fff);
              margin-right: 20px;
            }
            h3 {
              @include sc(20, #fff);
            }
          }
          .status2 {
            display: flex;
            align-items: center;
            > div {
              @include wh(20, 20);
              background-color: #fecb27;
              border-radius: 20px;
              margin-right: 18px;
            }
            h2 {
              @include sc(20, #fff);
              margin-right: 20px;
            }
            h3 {
              @include sc(20, #fff);
            }
          }
        }
      }
    }
    .search {
      width: 364px;
      height: 254px;
      box-shadow: 0px 3px 6px #000;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      .box {
        width: 300px;
        position: relative;
        h1 {
          @include sc(24, #fff);
          font-weight: bold;
          margin-bottom: 26px;
        }
        .searchItem {
          display: flex;
          line-height: 40px;
          color: #fff;
          h2 {
            @include sc(20);
            margin-right: 24px;
            color: #fff;
          }
          > div {
            width: 60px;
            height: 40px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
        }
        .searchIcon {
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 36px;
          height: 36px;
          background: rgba(64, 158, 255, 0.2);
          border: 1px solid rgba(64, 158, 255, 1);
          opacity: 1;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          i {
            @include sc(24, #409eff);
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    /deep/ .ivu-table th {
      background: #333;
      color: #fff;
    }
    /deep/ .ivu-table {
      background: unset;
    }
    /deep/ .ivu-table td {
      background: #1e1e1e;
      color: #fff;
    }
    /deep/ .ivu-table td,
    .ivu-table th {
      border-bottom: 1px solid #000;
    }
    /deep/ .ivu-table th {
      border-bottom: 1px solid #000;
    }
    /deep/ .ivu-tabs.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab {
      background: #313131;
      color: #fff;
    }
    .Data-tabs {
      /deep/.ivu-tabs-bar {
        margin: 0;
      }
      .TabItem {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        padding: 20px 20px;
        .suggestBox {
          > div {
            h1 {
              font-size: 24px;
              font-weight: bold;
              color: #fff;
              margin-bottom: 20px;
            }
            h2 {
              font-size: 18px;
              color: #fff;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
#EquipmentMaintenance::-webkit-scrollbar {
  width: 0;
}
</style>
